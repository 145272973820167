<template>
  <b-modal :visible="showModalPlanos" centered id="modal-center" @hide="hideModal" size="lg" :hide-footer="true">
    <div style="border-radius: 20px; border: 2px solid #f1f1f1;padding:3px
 ">
      <div class="row colunas" style="    
        color: #089f17; 
         
    ">
        <div class="col-3 linha-interna">Cobertura</div>
        <div class="col-3 linha-interna">Prata</div>
        <div class="col-3 linha-interna">
          Ouro
        </div>
        <div class="col-3 linha-interna2">Diamante</div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">
          Roubo e Furto qualificado
        </div>
        <div class="col-3" style=" border-right: 1px solid #66d569;padding-top:9px">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
        <div class="col-3" style=" border-right: 1px solid #66d569;padding-top:9px">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Assistência 24h</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <!-- <b-img style="width:20px" src="./img/success.webp"></b-img> -->
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Transporte e reboque</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <!-- <b-img style="width:20px" src="./img/success.webp"></b-img> -->
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Quebra por acidente</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Incêndio</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Queda de Raio</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Explosão</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Colisão</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Danos decorrente de transporte</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas">
        <div class="col-3 linha-interna">Danos a terceiros</div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna" style="align-self: center;padding-top:9px">
          <i style="font-size:20px" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
      <div class="row colunas-2">
        <div class="col-3 linha-interna">
          Ext. para transporte internacional
        </div>
        <div class="col-3 linha-interna">
          <i style="font-size:20px;padding-top:9px;" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3 linha-interna">
          <i style="font-size:20px;padding-top:9px;" class="fas fa-times-circle"></i>
        </div>
        <div class="col-3" style="align-self: center;">
          <b-img style="width:20px" src="./img/success.webp"></b-img>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ["showModalPlanos", "titulo"],
  data() {
    return {
      modificou: false,
    };
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data);
      this.loading = false;
    },
  },
};
</script>

<style>
.colunas {
  border-bottom: 1px solid #089f17;
  margin: 0px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  color: #757575;
}

.colunas-2 {
  margin: 0px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  color: #757575;
}

.linha-interna {
  border-right: 1px solid #089f17;
  padding: 10px;
  margin: 0px;
}

.linha-interna2 {
  padding: 10px;
  margin: 0px;
}

@media (max-width: 991px) {
  .linha-interna {
    border-right: 1px solid #089f17;
    padding: 2px;
    margin: 0px;
  }

  .linha-interna2 {
    padding: 2px;
    margin: 0px;
  }
}
</style>
